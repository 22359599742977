
import Vue from 'vue';
import CalculatorScreen from '@/components/common/CalculatorScreen/CalculatorScreen.vue';
import MicrosoftChangePeriod01Template from '@/templates/ms365/MicrosoftChangePeriod01Template.vue';

import { getMs365ServiceInfo } from '@/api/proxy/gapi';
import { createAddOrder, getOrderPossible } from '@/api/proxy/order';
import { getOperatingServiceInfo } from '@/api/proxy/service';
import { RouterType } from '@/router/types';

export const enum MS365_GOODS_INFO {
  BUSINESS_BASIC_MONTH = 52499,
  BUSINESS_STANDARD_MONTH = 52501,
  BUSINESS_APP_MONTH = 52503,

  BUSINESS_BASIC_YEAR = 52500,
  BUSINESS_STANDARD_YEAR = 52502,
  BUSINESS_APP_YEAR = 52504,
}

export default Vue.extend({
  components: {
    CalculatorScreen,
    MicrosoftChangePeriod01Template,
  },
  data() {
    return {
      // 이용 기간 변경은 무료이므로 [결제 금액 계산]을 모두 0원으로 표시
      goodsPriceInfo: {
        totalPrice: 0,
        totalPriceWithVat: 0,
        vat: 0,
        selectedGoods: [
          {
            goods_name: '이용 기간 변경',
            price: {
              origin_supply_price: 0,
              extra_days_origin_supply_price: 0,
              extra_days_supply_rule_price: 0,
              pricing_rule_list: [],
            },
            total_pricing: {
              total_price: 0,
              cut_price: 0,
            },
          },
        ],
        cutPrice: 0,
        totalRulePrice: 0,
      },
      // [상품 상세 정보]
      goodsDetailInfo: {
        service_name: 'Microsoft365',
        domain: '', // 도메인
        goods_type: '', // 상품 타입
        license_count: 0, // 해당 라이선스 갯수
        gtype: 0, // 상품 고유 아이디
      },
      oldServiceSeq: 0, // service_id
    };
  },
  computed: {
    // 통합 서비스 번호 (integrated_service_seqno)
    integratedServiceSeqno(): number {
      return this.$store.state.goodsInfo?.integratedServiceSeqno ?? 0;
    },
    userId(): string {
      return this.$store.state.userInfo?.user_id ?? '';
    },
  },
  async mounted() {
    try {
      if (!this.integratedServiceSeqno) {
        throw new Error('정상적인 주소 정보가 아닙니다.');
      }

      const serviceInfo = await getOperatingServiceInfo(this.integratedServiceSeqno);

      if (!serviceInfo) {
        throw new Error('해당 서비스 정보를 찾을 수 없습니다.');
      }

      this.$console.log(serviceInfo, '통합 서비스 seq로 조회한 내용');

      // service_id
      this.oldServiceSeq = serviceInfo?.service_main_goods_list[0].service_seqno;

      const ms365ServiceInfo = await getMs365ServiceInfo({
        'data[user_id]': this.userId,
        'data[service_seq]': this.oldServiceSeq,
        'data[options[0]]': 'hosting',
        'data[options[1]]': 'goods',
        'data[options[2]]': 'license',
        'data[options[3]]': 'client',
      });

      const { goods, hosting, client, license } = ms365ServiceInfo;

      if (this.userId !== client.user_id) {
        throw new Error('로그인 유저와 조회 유저가 동일하지 않습니다.');
      }

      if (goods.term_unit_code.code !== 'month') {
        throw new Error('해당 상품은 1개월 상품이 아닙니다.');
      }

      this.$store.commit('setChangePeriod', {
        msoffice365: {
          goods,
          hosting,
          client,
          license,
        },
      });

      this.goodsDetailInfo = {
        ...this.goodsDetailInfo,
        domain: hosting.domain, // 도메인
        goods_type: goods.expose_name, // 상품 타입
        license_count: license.license_count || 0, // 해당 라이선스 갯수
        gtype: hosting.gtype,
      };
    } catch (err) {
      // 오류 발생시 (상세 오류 메세지는 표시하지 않음)
      alert('오류가 발생하였습니다.');
      window.location.href = process.env.VUE_APP_GABIA_URL;
      return;
    } finally {
      this.$GlobalLoading.hide();
    }
  },
  methods: {
    async nextStepBtnClick() {
      try {
        this.$GlobalLoading.show();

        const getOrderPossibleResult = await getOrderPossible(this.integratedServiceSeqno);

        if (getOrderPossibleResult?.result === 'N') {
          // 주문서 생성 불가능 에러 코드(1, 2, 3)에 따른 분기
          if (getOrderPossibleResult?.fail_code === '1' || getOrderPossibleResult?.fail_code === '3') {
            alert('다른 신청 내역이 이미 존재합니다.');
          } else if (getOrderPossibleResult?.fail_code === '2') {
            alert(
              '이용 기간 변경 신청 내역이 이미 존재합니다. 이용 기간 변경 신청 후 완료되기까지 시간이 다소 소요될 수 있습니다.',
            );
          }

          this.$GlobalLoading.hide();
          return;
        }

        // 변경 주문서 API
        await createAddOrder({
          checkout: {
            // 통합 서비스 번호
            service: {
              seqno: this.integratedServiceSeqno, // 통합 서비스 seq
            },
            settlement_option: {
              dep_item: 'MS1',
            },
            total_price: 0,
            change_goods_list: [
              {
                goods_type: 'main', // 주상품
                service_main_goods_seqno: this.oldServiceSeq, // 주상품 seqno
                change_goods_id: this.getChangeGoodsId(this.goodsDetailInfo.gtype), // 변경되는 상품 아이디
                quantity: this.goodsDetailInfo.license_count, // 라이선스 갯수
                sort_order: 0,
              },
            ],
          },
        });

        // 이용 기간 변경 완료 페이지 이동
        this.$router.push({ name: `${RouterType.CHANGE_PERIOD}-02`, params: { service_code: 'msoffice365' } });
      } catch (err) {
        // alert('잘못된 접근입니다.');
        this.$console.log(err, '이용 기간 변경 페이지 [다음 단계] 오류');
      }
    },
    getChangeGoodsId(goods_id) {
      const goodsId = Number(goods_id);
      // 1개월 / Microsoft 365 Business Basic
      if (goodsId === MS365_GOODS_INFO.BUSINESS_BASIC_MONTH) {
        return MS365_GOODS_INFO.BUSINESS_BASIC_YEAR; // 1년형 / Microsoft 365 Business Basic
      }
      // 1개월 / Microsoft 365 Business Standard
      else if (goodsId === MS365_GOODS_INFO.BUSINESS_STANDARD_MONTH) {
        return MS365_GOODS_INFO.BUSINESS_STANDARD_YEAR; // 1년형 / Microsoft 365 Business Standard
      }
      // 1개월 / 비즈니스용 Microsoft 365 앱
      else if (goodsId === MS365_GOODS_INFO.BUSINESS_APP_MONTH) {
        return MS365_GOODS_INFO.BUSINESS_APP_YEAR; // 1년형 / 비즈니스용 Microsoft 365 앱
      }
    },
  },
});
