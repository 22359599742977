
import Vue from 'vue';
import BillLayout from '@/layouts/BillLayout.vue';
import FormLayout from '@/layouts/FormLayout.vue';
import TableLayout from '@/layouts/TableLayout.vue';
import ColumnLayout from '@/layouts/ColumnLayout.vue';

import formTitle from '@/assets/data/formTitle/index.json';

export default Vue.extend({
  components: {
    FormLayout,
    BillLayout,
    TableLayout,
    ColumnLayout,
  },
  model: {
    prop: 'formState',
    event: 'change',
  },
  props: {
    goodsDetailInfo: {
      type: Object,
      default() {
        return {
          service_name: String,
          domain: String, // 도메인
          goods_type: String, // 상품 타입
          license_count: Number, // 해당 라이선스 갯수
        };
      },
    },
  },
  data() {
    return {
      formTitle,
    };
  },
});
